import { scrollToHTMLElement } from "../../../../etc/functions"
import Formstack from "../../formstack/models"
import { FormSubmissionFailed, FormSubmitted, FormSubmitting } from "../../formstack/views"

import styles from "../assets/css/index.module.scss"

const FormWithTitleBlock: React.FC<{
	formstackID: number
	formTitle: string
	formLinks?: { href: string; text: string }[]
	formClasses?: string
	additionalRules?: string[]
}> = ({ formstackID, formTitle, formLinks, formClasses, additionalRules }) => {
	const formstackParams = {
		formID: formstackID,
		options: {
			finalCallback: () => {
				// This is very important for mobile devices when the form is pretty long and after submission
				// we have to scroll user to the top part of the form to show the submission message exactly in viewport
				scrollToHTMLElement(document.getElementById("contactFormScrollTo"))
			},
			noRichText: false,
			submittingMessage: <FormSubmitting />,
			submittedMessage: <FormSubmitted />,
			submissionFailedMessage: <FormSubmissionFailed />
		}
	}

	return (
		<div id="contactFormScrollTo" className={`${formClasses ? formClasses : ""}`}>
			<div className={`px-3 px-md-4 ${styles.formTitleBlock}`}>
				<div className={`mx-auto ${styles.maxXXL}`}>
					<h2
						dangerouslySetInnerHTML={{ __html: formTitle }}
						className={`d-flex flex-wrap justify-content-center text-uppercase text-white ${styles.fontBlackExtended}`}
					></h2>
				</div>
			</div>

			<div id="contactForm" className={`${styles.formContainer}`}>
				<div className={`mx-auto ${styles.form} ${styles.maxXL}`}>
					<Formstack {...formstackParams} />

					{formLinks && (
						<div
							className={`mx-auto mt-3 mt-md-2 d-flex flex-column align-items-center align-items-md-end gap-3`}
						>
							{formLinks.map((link, index) => {
								return (
									<a
										key={index}
										href={link.href}
										target="_blank"
										rel="noreferrer"
										className={styles.link}
									>
										{link.text}
									</a>
								)
							})}
						</div>
					)}

					{additionalRules && (
						<ul className={styles.rules}>
							{additionalRules.map((rule, index) => (
								<li key={index} dangerouslySetInnerHTML={{ __html: rule }}></li>
							))}
						</ul>
					)}
				</div>
			</div>
		</div>
	)
}

export default FormWithTitleBlock
